@import "../../assets/css/var.scss";

.drag-panel {
  height: calc(100% - 100px); // exclude button height and padding
  margin-top: 20px;
  border: 1px solid $theme-color;
  padding: 16px;

  .small-icon {
    width: 30px;
    height: 30px;
  }
  .icons {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .drop-box {
    width: 70px;
    height: 70px;
    img {
      width: 55px;
      height: 55px;
    }
  }
}

.drop-panel {
  border: 1px solid $theme-color;
  padding: 16px;

  .ui-button {
    margin-top: 12px;
  }
  .dropped-items {
    height: 100%;
    padding: 10px;
    width: 50%;

    &.file-icons {
      flex-direction: column;
    }
    .dropbox {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $theme-color;
      height: 90px;
      width: 90px;
      position: relative;
      text-align: center;
      overflow: hidden;

      &.with-title {
        img {
          max-width: 80%;
        }
      }

      .overlay {
        top: 0px;
      }

      .drop-box {
        margin: 0;
      }
    }

    .csv-dropbox-2 {
      margin-top: 100px;
    }
  }
}

.overlay {
  position: absolute;
  height: 90px;
  width: 90px;
  top: 8px;
  left: 8px;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  background-color: $light;
  opacity: 0.5;
  img {
    cursor: pointer;
    width: 20px !important;
  }
}

.overlay-automation {
  left: 0 !important;
}

.drop-box {
  width: 90px;
  height: 90px;
  border: 1px solid $theme-color;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 8px;
  overflow: hidden;

  img {
    width: 80px;
    height: 80px;
  }

  &.with-title {
    font-size: 12px;
    img {
      max-width: 55%;
    }
  }
}

.play-btn {
  margin-top: 12px !important;
}

.graph-container {
  height: calc(100vh - 190px);
  overflow-y: auto;
  overflow-x: hidden;
}

.automation-sections-wrapper {
  // width: 49%;
  &:first-child {
    width: 35%;
  }
  &:last-child {
    width: 60%;
    margin-left: 10px;
  }
}

.graph-container {
  width: 50%;
}

.reset-automation {
  right: 20px;
  top: 110px;
  background-color: red !important;
  color: white !important;
}

// .automation-graph {
//   width: 100% !important;
//   .svg-container {
//     width: 100% !important;
//     svg {
//       width: 100% !important;
//     }
//   }
// }

.container-1 {
  display: flex;
  width: 50%;
  // justify-content: space-around;
}

.work-bench--container {
  margin-top: 20px;
  height: calc(100% - 100px);
}

@media (max-width: 1280px) {
  .container-1 {
    display: flex;
    justify-content: space-around;
    height: 700px;
    width: 100%;
  }
  .chart-container {
    width: 100%;
  }
  .automation-sections-wrapper {
    &:last-child {
      padding: 0;
      width: 100%;
      margin-left: 10px;
    }
  }

  .graph-container {
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 540px) {
  .container-1 {
    display: block;
    height: fit-content;
    &:first-child {
      width: 100%;
    }
  }

  .automation-sections-wrapper {
    width: 100%;
    &:first-child {
      width: 100%;
    }
    &:last-child {
      margin-left: 0 !important;
    }
  }

  .work-panel {
    margin: 0;
    margin-top: 20px;
  }
}
