@media (min-width: 1800px) {
  .admin-tabs {
    .tab-link {
      height: 36px;
      padding: 0 14px;
      font-size: 14px;
      border-radius: 5px 5px 0 0;
    }
  }
  body {
    font-size: 16px !important;
  }
  .head-lg {
    font-size: 30px;
  }
  html,
  body,
  .form-label {
    font-size: 16px;
  }
  .theme-ip {
    height: 48px;
    min-height: 48px;
    font-size: 16px;
  }
  .icon-btn {
    font-size: 18px;
  }
}

@media (min-width: 1600px) {
  .margin-top-25 {
    margin-top: 25px;
  }
}
@media (max-width: 1600px) {
  .margin-top-25 {
    margin-top: 19px;
  }
}

@media (max-width: 1400px) {
  body {
    font-size: 14px;
  }
  .admin-nav {
    padding: 15px 0;
  }
  .sidebar {
    width: 220px;
  }
  .side-head img {
    max-width: 100px;
  }
  .side-head .img .fav {
    max-width: 136px;
  }
  .side-head {
    padding: 15px;
  }
  .sidebar .menu-links .link {
    padding: 6px 24px;
    border-radius: 7px;
  }
  .admin-home {
    margin-left: 220px;
    width: calc(100vw - 220px);
  }
  .admin-card .head {
    min-height: 44px;
  }
  .head-md {
    font-size: 18px;
  }
  .theme-ip.sm {
    min-height: 28px;
    max-height: 28px;
  }
  .admin-tabs {
    .theme-ip,
    .tab-link {
      height: 35px;
      max-height: 30px;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .theme-ip {
    height: 35px;
  }
  .admin-nav .user-drop .dropdown-toggle {
    height: 35px;
    width: 35px;
  }
  .data-bar .bar {
    height: 13px;
  }
  .data-bar {
    margin-bottom: 5px;
  }
  .theme-table td {
    padding: 7px 15px;
  }
  .theme-table th {
    padding-left: 15px;
  }

  .date {
    font-size: 13px;
  }
  .price-row {
    .symbol,
    .term {
      font-size: 12px;
    }
    .value {
      font-size: 26px;
    }
  }
  .big-txt {
    font-size: 26px;
  }
  .sq-icon {
    font-size: 10px;
    height: 20px;
    width: 20px;
  }
  .head-lg {
    font-size: 22px;
    margin-bottom: 5px;
  }
  .chart-data {
    gap: 20px;
  }
  .md-head {
    font-size: 12px;
  }
  .theme-table {
    font-size: 12px;
  }
  .card-sub {
    margin: 0;
    .content {
      margin: 0 0 0 10px;
    }
    .btn-row .theme-btn {
      min-width: 100px;
    }
  }
  // .admin-header{
  //   // width: calc(100% - 279PX);
  //   right: 30px;
  // }
}

@media screen and (min-width: 1300px) {
  .filter-card {
    .table-outer {
      .align {
        text-align: left;
      }
    }
  }
}
/***************Aditya*********/
@media (max-width: 1023px) {
  .divider-left {
    border-top: 2px dashed $ip-bg;
    padding-top: 30px;
    margin-top: 20px;
    border-left: none;
  }
  .head-404 {
    font-size: 48px;
  }
}

/***Aditya*****/

@media (min-width: 992px) {
  .admin-nav {
    .center-logo {
      display: none;
    }
    .toggler {
      display: none;
    }
  }
}

@media (max-width: 991px) {
  .admin-home {
    width: 100%;
    margin-left: 0;
  }
  .sidebar-open {
    .admin-home {
      width: 100%;
      margin-left: 0;
    }
    .sidebar {
      @include prefix(box-shadow, 20px 0 25px rgba($dark-2, 0.5));
      @include prefix(transform, translateX(0));
      width: 280px;
      z-index: 9999;
    }
    .sidebar .side-head .img {
      width: auto;
    }
    .sidebar .menu-links .link .text {
      opacity: 1;
    }
    .sidebar-toggler .bar.m {
      margin-left: auto;
      &:after {
        left: -10px;
        @include prefix(transform, rotate(45deg));
      }
    }
  }

  .sidebar {
    @include prefix(transform, translateX(-100%));
    @include prefix(box-shadow, none);
  }
  .admin-header {
    // width: calc(100% - 59PX);
    top: 0;
    width: 100%;
    right: 0;
  }
  // .theme-btn.fs-28{font-size: 28px;}
}
@media (min-width: 768px) {
  .sec-info {
    .right {
      .filter-responsive {
        visibility: hidden;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 850px) {
  .theme-btn.fs-28 {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .operation-row {
    padding-bottom: 10px;
    .data-box {
      min-width: 120px;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .s-head {
      justify-content: center;
      .md-head {
        text-align: center;
      }
    }
    .border-resp {
      border-left: 1px solid #dee2e6;
    }
    .resp-gap {
      display: flex;
      margin: 0 -15px;
      & > div {
        padding: 0 15px;
      }
    }
  }

  .btn-list {
    .theme-btn {
      margin-bottom: 23px;
    }
  }
  .filter-card {
    position: relative;
    .row-responsive {
      display: block;
      .left {
        &.click-block {
          display: block;
          transition: width 0.3s ease-in-out;
          position: absolute;
          right: 0px;
          top: 0px;
          background: $white;
          width: 100%;
          min-height: 100%;
        }
        max-width: 100%;
        display: none;
        transition: width 0.3s ease-in-out;
      }
      .right {
        max-width: 100%;
        padding-right: 11px;
        padding-left: 9px;

        .table-outer {
          border-left: none;
        }
      }
    }

    .filter-sidebar {
      .filter-block {
        // margin-top: 34px;
      }
    }
  }
  .md-justify {
    justify-content: center;
  }

  .sec-info {
    .right {
      .filter-responsive {
        visibility: visible;
        margin-bottom: 10px;
      }
    }
  }

  .admin-tabs {
    .inner {
      .react-tabs__tab {
        .tab-link {
          min-width: 150px;
          font-size: 12px;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .sidebar .bottom-content {
    .filled {
      display: none;
    }
  }
  .hd-search-toggle {
    display: none;
  }
}

@media (max-width: 576px) {
  .scroll-sec .admin-card {
    flex: 0 0 250px;
  }
  .theme-table {
    .address-cell {
      width: 100%;
    }
  }
  .main-data {
    margin-top: 0px;
  }
  .admin-nav {
    position: relative;
    z-index: 999;
    .search-autodrop {
      top: 100%;
      left: -30px;
      width: auto !important;
      right: -30px;
      border-radius: 0px !important;
      padding-top: 30px;
      max-height: calc(100vh - 65px);
      overflow: auto;
    }
    .search-box {
      margin-right: 0;
      position: static;
      min-width: unset;
      &.open {
        .hd-search-toggle:before {
          content: "\f00d";
        }
        .search-outer {
          display: block;
          height: 100%;
        }
      }
    }
    .input-wrap.search-outer .theme-ip {
      max-width: 100%;
      height: 100%;
      border-radius: 0;
      border-width: 0px 0px 1px 0px;
    }
    // .user-drop {display: none;}
    .search-outer {
      position: absolute;
      left: 0;
      top: 0;
      width: auto;
      right: 0;
      display: none;
      margin: 0 -30px;
    }
    .hd-search-toggle {
      position: relative;
      z-index: 6;
    }
  }
  .chart-data {
    gap: 20px;
  }
  .big-txt,
  .price-row .value {
    font-size: 26px;
  }
  .head-md {
    font-size: 17px;
  }
  .head-lg {
    font-size: 22px;
  }
  .data-box {
    padding: 15px 5px;
    & + .data-box {
      padding-top: 0;
    }
  }
  .input-wrap.search-outer .theme-ip {
    max-width: 190px;
  }
  .admin-tabs .tab-link {
    min-width: 121px;
  }
  .admin-card .head {
    &.responsive {
      flex-direction: column;
      padding-top: 10px;
      padding-bottom: 10px;
      .left {
        margin-bottom: 10px;
      }
    }
  }
  .sidebar {
    .menu-links {
      .link {
        justify-content: flex-start;
        .text {
          margin-left: 15px;
        }
      }
    }
    .bottom-content {
      .menu-links {
        display: flex;
        justify-content: center;
      }
      .item {
        padding: 10px 27px;
        &.filled {
          .link {
            background-color: $text-color;
            border: none;
          }
        }
      }
      .link {
        @include prefix(border-radius, 30px);
        .text {
          margin-left: 15px;
        }
      }
    }
    .sidebar-toggler {
      position: absolute;
      right: 25px;
      top: 30px;
    }
  }
  .side-head {
    justify-content: center !important;
    .img {
      flex-direction: column;
      .fav {
        margin-right: 0;
        margin-bottom: 12px;
      }
    }
  }
  .hide-mob {
    display: none;
  }
  .big-chart-card {
    margin-left: -30px;
    margin-right: -30px;
    border: none;
    border-radius: 0px;
    margin-top: 50px;
    .head {
      @include prefix(border-radius, 0px);
      border: none;
    }
    .body {
      background-color: #f7f7f7;
    }
    .chart-outer {
      g[class$="background"] rect:first-child {
        fill: #f7f7f7 !important;
      }
    }
  }
  .resp-card {
    margin-left: -30px;
    margin-right: -30px;
    border: none;
    margin-bottom: 20px;
    .head {
      @include prefix(border-radius, 0px);
      border: none;
      background-color: transparent;
    }
    &.gray {
      border-radius: 0px;
      .head {
        background-color: #f7f7f7;
      }
    }
  }
  .select-row .theme-ip {
    min-width: 100px;
  }
  .admin-nav {
    .left-bar a .f-med {
      display: none;
    }
  }
  .chart-data {
    .data {
      display: flex;
      align-items: center;
      .price-row {
        margin-left: 5px;
        margin-top: 5px;
      }
    }
  }
  .admin-card .chart {
    height: 220px;
  }
  .card-row-change {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    .data-box {
      padding: 5px;
      .big-txt {
        color: $text-color;
      }
    }
  }
  .form-group {
    margin-bottom: 15px;
  }
  .theme-table {
    td {
      a {
        white-space: nowrap;
      }
    }
  }
  .chart-data.revn .revn {
    border-top: 1px solid $border-clr;
  }
  .admin-tabs .inner .react-tabs__tab .tab-link {
    min-width: 140px;
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .card-sub .btn-row {
    flex-direction: column;
  }
  .card-sub .upper {
    margin-bottom: 5px;
  }
  .theme-btn.fs-28 {
    font-size: 18px;
  }
  .equipment-btn {
    font-size: 15px !important;
  }
  .control-head {
    .right {
      display: flex;
    }
  }
}
@media (max-width: 420px) {
  .control-head {
    .head {
      .head-lg {
        font-size: 18px;
      }
    }

    .right {
      .icon-btn {
        margin-left: 5px;
        height: 26px;
        width: 26px;
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 370px) {
  .inner-container {
    padding: 0 15px;
  }
  .big-chart-card,
  .resp-card {
    margin-left: -15px;
    margin-right: -15px;
  }
  .admin-nav {
    .search-outer {
      margin: 0 -15px;
    }
    .search-autodrop {
      left: -15px;
      right: -15px;
    }
  }
  .admin-tabs {
    .theme-ip {
      padding-right: 5px;
    }

    .inner {
      .react-tabs__tab {
        .tab-link {
          min-width: 127px;
          font-size: 12px;
        }
      }
    }
  }
}

// dhyey

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .theme-btn {
    font-size: 18px !important;
  }
}

@media (max-width: 370px) {
  .find-tab-scroll-trail {
    width: 255px;
  }
  .Table-outer-audit {
    overflow-x: auto;
  }
}

@media (max-width: 576px) {
  .crystallization-model-btn {
    flex-direction: column;
    gap: 5px;
  }
}

@media (min-width: 576px) and (max-width: 1339px) {
  .crystallization-model-btn {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 2rem;
  }
}

@media (max-width: 769px) {
  .solubility-screening-table {
    display: flex;
    flex-direction: column;
  }
}
