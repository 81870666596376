@import "slick.scss";
@import "tailwind.scss";
@import "var.scss";
@import "mixin.scss";
@import "reset.scss";
@import "typo.scss";

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  @extend .f-medium;
  color: $text-color;
  height: 100%;
  font-size: 1rem;
  line-height: 1.6;
  overflow-x: hidden;
}

.width-full {
  width: 100%;
}

.height-full {
  height: 100%;
}

.sec-pd {
  padding: 130px 0;
}

.sm-pd {
  padding: 40px 0;

  &.pb-0 {
    padding-bottom: 0 !important;
  }
}

.min-h-500 {
  min-height: 500px;
}

.lg-row {
  margin: 0 -30px;

  & > div[class*="col-"] {
    padding: 0 30px;
  }
}

.md-row {
  margin: 0 -10px;

  & > div[class*="col-"] {
    padding: 0 10px;
  }
}

.xs-row {
  margin: 0 -8px;

  & > div[class*="col-"] {
    padding: 0 8px;
  }
}

.xxs-row {
  margin: 0 -3px;

  & > div[class*="col-"] {
    padding: 0 3px;
  }
}

/*********common css ends*********/
.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.pr-5 {
  padding-right: 5px;
}

.bg-none {
  background: none !important;
}

.text-center {
  text-align: center !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.row {
  @extend .flex, .flex-wrap, .-mx-4;

  &.sm-row {
    @extend .-mx-3;

    & > div[class*="col-"] {
      @extend .px-3;
    }
  }
}

.col-6 {
  @extend .px-4, .w-full;
  max-width: 50%;
}

.col-md-8 {
  @extend .md\:w-8\/12, .w-full, .px-4;
}

.col-lg-9 {
  @extend .lg\:w-3\/4, .w-full, .px-4;
}

.col-lg-6 {
  @extend .lg\:w-1\/2, .w-full, .px-4;
}

.col-md-4 {
  @extend .md\:w-1\/3, .w-full, .px-4;
}

.col-md-5 {
  @extend .md\:w-2\/5, .w-full, .px-4;
}

.col-lg-7 {
  @extend .lg\:w-3\/5, .w-full, .px-4;
}

.col-lg-5 {
  @extend .lg\:w-2\/5, .w-full, .px-4;
}

.col-lg-4 {
  @extend .lg\:w-1\/3, .w-full, .px-4;
}

.col-lg-3 {
  @extend .lg\:w-1\/4, .w-full, .px-4;
}

.col-lg-2 {
  @extend .lg\:w-1\/5, .w-full, .px-4;
}

.col-sm-5 {
  @extend .sm\:w-2\/5, .w-full, .px-4;
}

.col-sm-7 {
  @extend .sm\:w-3\/5, .w-full, .px-4;
}

.col-md-6 {
  @extend .md\:w-1\/2, .w-full, .px-4;
}

.col-12 {
  @extend .w-full, .px-4;
}

.col-lg-8 {
  @extend .lg\:w-8\/12, .w-full, .px-4;
}

.col-md-3 {
  @extend .md\:w-1\/4, .w-full, .px-4;
}

.col-md-9 {
  @extend .md\:w-3\/4, .w-full, .px-4;
}

.col-xl-8 {
  @extend .xl\:w-8\/12, .w-full, .px-4;
}

.col-xl-4 {
  @extend .xl\:w-1\/3, .w-full, .px-4;
}

.tiny {
  .header {
    padding: 0px !important;
  }
}

.col-lg-4 {
  @extend .w-full, .px-4;
  position: relative;
}

/*********common css ends*********/

// instruction-modal
.instruction-modal {
  max-height: 253px;
  overflow-y: auto;
  overflow-x: hidden;

  content {
    padding-top: 30px;

    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

// User-List
.user-table {
  .f-heavy {
    @extend .flex;
    align-items: center;
  }
}

.user-circle {
  // background-color: $text-light-3;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background: $ip-bg;
  @extend .full-flex;
  margin-right: 10px;
  // padding: 6px;
  // overflow: hidden;

  img {
    border-radius: 50%;
  }
}

// User-lisy

/*********heading css starts********/
.head-xxl {
  font-size: 105px;
  @extend .f-med;
  color: $text-alter;
}

.head-xl {
  font-size: 40px;
  margin-bottom: 5px;
}

.head-lg-2 {
  color: $dark-2;
  font-size: 50px;
  margin-bottom: 35px;
  line-height: 1.1;
}

.head-lg {
  font-size: 26px;
  margin-bottom: 0px;
  line-height: 1.5;
  color: $dark-2;
  line-height: 1.1;
  @extend .f-heavy;

  &.head-404 {
    font-size: 64px;

    @media screen and (max-width: 1400px) {
      font-size: 56px;
    }

    @media (max-width: 1023px) {
      font-size: 48px;
    }

    @media (max-width: 991px) {
      font-size: 42px;
    }

    @media (max-width: 768px) {
      font-size: 38px;
    }

    @media (max-width: 575px) {
      font-size: 32px;
    }

    @media (max-width: 400px) {
      font-size: 24px;
    }
  }

  &.automation {
    font-size: 20px;
  }

  &.bold {
    @extend .n-bolder;
    color: $theme-color;
  }

  &.light {
    opacity: 0.1;
    color: $dark-2;
  }
}

.head-md {
  font-size: 20px;
  color: $dark-2;
  line-height: 1.4;
  margin-bottom: 0px;
  @extend .f-med;
}

.head-md-2 {
  font-size: 40px;
  line-height: 1.4;
  @extend .n-light;
}

.head-sm {
  font-size: 24px;
  margin-bottom: 18px;

  &.mb-0 {
    margin-bottom: 0;
  }
}

.head-sm-2 {
  font-size: 20px;
  color: $text-light;
  @extend .f-bold;
  margin-bottom: 25px;
}

.head-bg {
  padding: 5px 15px;
  border-left: 3px solid $text-color;
  @extend .f-heavy;
  background: rgba($text-color, 0.1);
}

.md-text {
  font-size: 1.1rem;
}

/*********heading css ends*********/

/*********btn css start*********/
.theme-btn {
  @extend .inline-flex,
    .items-center,
    .slow,
    .radius-0,
    .justify-center,
    .radius-6,
    .f-med;
  background-color: $theme-color;
  min-height: 50px;
  white-space: nowrap;
  border: 1px solid transparent;
  padding: 10px 17px;
  min-width: 200px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  color: $white;

  &.invert-colors {
    background-color: $white;
    color: $theme-color;
    border: 1px solid $theme-color;

    &:hover {
      background-color: $white;
      color: $theme-secondary;
      border: 1px solid $theme-secondary;
    }
  }

  &:hover {
    color: $white;
    background-color: $theme-secondary;
  }

  &.border-btn {
    border-color: $theme-color;
    color: $theme-color;
    background: none;
  }

  &.curve {
    @extend .radius-8;
  }

  i {
    margin-right: 5px;
  }

  &.md {
    min-width: 85px;
    min-height: 34px;
    font-size: 16px;
    padding: 5px 15px;
    @extend .radius-3;
  }

  &.md-2 {
    height: 42px;
    min-height: 42px;
    @extend .radius-5;
  }

  &[disabled] {
    opacity: 0.45;
  }

  &.alter {
    background: transparent;
    border-color: $theme-color;
    color: lighten($dark, 26.67);
  }

  &.disabled {
    background-color: darken($white, 20) !important;
    cursor: not-allowed;
  }

  &.sm {
    min-width: auto;
    font-size: 12px;
    min-height: 30px;
    @extend .radius-5;
    padding: 4px 14px;

    &.curve {
      @extend .radius-5;
    }
  }

  &.plain-btn {
    border-color: transparent;
    color: $dark-2;
    background: transparent;
  }

  &.white {
    background-color: $white;
    color: $dark-2;

    &:hover {
      background-color: $theme-color;
      color: $white;
    }
  }

  &.light-btn {
    background: $ip-bg;
    color: $text-alter;
  }

  &.dark-btn {
    background-color: $dark-2;
    color: $white;
  }

  &.brick {
    min-height: 80px;
    font-size: 20px;
  }

  &.light {
    background: rgba($theme-color, 0.2);
    color: $theme-color;
  }

  &.fs-28 {
    font-size: 28px;
  }

  &.light-btn {
    background: $ip-bg;
    color: $text-alter;
  }

  &.dark-btn {
    background-color: $dark-2;
    color: $white;
  }

  &.brick {
    min-height: 80px;
    font-size: 20px;
  }

  &.light {
    background: rgba($theme-color, 0.2);
    color: $theme-color;
  }

  &.fs-28 {
    font-size: 28px;
  }

  &.btn-md {
    font-size: 20px;
  }
}

.action-btn {
  margin-bottom: 30px;
}

.icon-btn {
  @extend .inline-flex, .items-center, .justify-center, .radius-3, .slow;
  height: 34px;
  width: 34px;
  font-size: 16px;
  background: $theme-color;
  color: $white;

  &:hover {
    background: $white;
    color: $theme-color;
  }

  &.sm {
    height: 28px;
    width: 28px;
    font-size: 14px;
  }

  &.plain {
    background: transparent;
    color: $text-light;

    &:hover {
      background: rgba($theme-color, 0.07);
      color: $theme-color;
    }
  }

  &.xs {
    height: 28px;
    width: 28px;
    font-size: 14px;
  }

  &.white {
    background: $white;
    color: $theme-color;
  }

  &.alter {
    background: $text-color;
    color: $white;
  }
}

/*********btn css ends*********/

/*********info css starts*********/

.bg-theme {
  background: $theme-color;
}

.theme-txt {
  color: $theme-color;
}

.sortable-link {
  /*color: $theme-color;*/
}

.sortable-link:hover {
  color: $text-light;
  cursor: pointer;
}

/*********info css ends*********/

/*********container css starts*********/
.border-none {
  border: none !important;
}

.shadow-none {
  @include prefix(box-shadow, none);
}

.inner-container {
  @include wrapper(2570px);
  padding: 0 30px;
}

.lg-container {
  @include wrapper(1455px);
}

.md-container {
  @include wrapper(1243px);
}

.md-container-2 {
  @include wrapper(980px);
}

.sm-container {
  @include wrapper(830px);
}

.sm-container-2 {
  @include wrapper(630px);
}

.xs-container {
  @include wrapper(380px);
}

.fa {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}

/*********container css starts*********/

/*********override css starts*********/

p.error,
div.error,
label.error {
  color: $danger-color;
  font-size: 13px;
  margin: 0;
  vertical-align: top;
}

.full-vh {
  min-height: 100vh;
}

.b-l {
  border-left: 1px solid $border-clr;
}

.b-r {
  border-right: 1px solid $border-clr;
}

.b-t {
  border-top: 1px solid $border-clr;
}

.b-b {
  border-bottom: 1px solid $border-clr;
}

.b-x {
  @extend .b-r, .b-l;
}

.b-y {
  @extend .b-b, .b-t;
}

.p-0 {
  padding: 0 !important;
}

/*********override css starts*********/

.input-wrap {
  div[class$="-ValueContainer"] {
    padding-left: 13px;
  }

  div[class$="-placeholder"] {
    color: $text-color;
  }

  div[class$="-menu"] {
    & > div {
      max-height: 200px;
    }
  }

  .react-select__option {
    &:hover {
      background-color: lighten($dark, 96);
    }
  }

  div[class*="-is-focused"] {
    background-color: lighten($dark, 96);
  }

  div[class*="-is-selected"],
  div[class*="-is-selected"]:hover {
    background-color: lighten($theme-color, 80);
    color: $theme-color;
  }

  .react-select__indicator {
    position: relative;

    &:before {
      @extend .icon-after, .fa;
      color: $text-color;
      font-size: 12px;
      content: "\f107";
      right: 17px;
      pointer-events: none;
    }

    svg {
      opacity: 0;
    }
  }

  .select__menu {
    z-index: 99999999999;
    position: absolute;

    // .select__menu {
    //   z-index: 99999;
    //   position: relative;
    // }
  }

  .react-select__indicator-separator {
    opacity: 0;
  }

  .select__control {
    outline: none;

    &:hover {
      outline: rgba($danger-color, 0.1);
      border: 1px solid $danger-color;
    }
  }
}

/*********commmon css starts*********/
.radius-0 {
  @include prefix(border-radius, 0px);
}

.radius-3 {
  @include prefix(border-radius, 3px);
}

.radius-5 {
  @include prefix(border-radius, 5px);
}

.radius-6 {
  @include prefix(border-radius, 6px);
}

.radius-8 {
  @include prefix(border-radius, 8px);
}

.radius-10 {
  @include prefix(border-radius, 10px);
}

.radius-12 {
  @include prefix(border-radius, 12px);
}

.radius-15 {
  @include prefix(border-radius, 15px);
}

.radius-20 {
  @include prefix(border-radius, 20px);
}

.radius-22 {
  @include prefix(border-radius, 22px);
}

.radius-50 {
  @include prefix(border-radius, 50px);
}

.radius-round {
  @include prefix(border-radius, 50%);
}

.full-abs {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.x-abs {
  @include center(true, false);
}

.y-abs {
  @include center(false, true);
}

.xy-abs {
  @include center();
}

.theme-shadow {
  @include prefix(box-shadow, 0px 3px 90px rgba($dark, 0.11));

  &.sm {
    @include prefix(box-shadow, 5px 5px 8px rgba($dark, 0.16));
  }
}

a,
.slow {
  @include prefix(transition, 0.3s ease);
}

.slow-2 {
  transition: 0.4s ease-in-out;
}

.slow-3 {
  transition: 0.25s ease-in-out;
}

.no-slow {
  @include prefix(transition, none);
}

.full-flex {
  @extend .flex, .justify-center, .items-center;
}

.black-link {
  color: $dark;

  &:hover {
    color: $theme-color;
  }
}

.text-link {
  color: lighten($text-color, 5);

  &:hover {
    color: $theme-color;
  }
}

.icon-before,
.icon-after {
  @include center(false, true);
}

.icon-before {
  left: 15px;
}

.icon-after {
  right: 15px;
}

textarea.theme-ip {
  height: auto !important;
  max-height: auto !important;
}

.input-wrap {
  position: relative;
  font-size: 12px;

  .react-datepicker__input-container .react-datepicker__calendar-icon {
    height: 34px;
    width: 34px;
    right: 0;
    opacity: 0.3;
    top: 2px;
    pointer-events: none;
  }

  .react-datepicker__view-calendar-icon input {
    padding: 6px 28px 5px 15px;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    background: $theme-color;
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover {
    background: rgba($theme-color, 0.4);
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected {
    background: rgba($theme-color, 0.4);
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background: $theme-color;
  }

  &.with-icon {
    .theme-ip {
      padding-left: 40px;
    }
  }

  &.select-outer {
    &:after {
      @extend .icon-after, .fa;
      color: $text-color;
      font-size: 12px;
      content: "\f107";
      right: 18px;
      pointer-events: none;
      z-index: 6;
    }

    .theme-ip {
      padding-right: 35px;
    }
  }

  &.search-outer {
    .theme-ip {
      padding-left: 35px;
    }

    &:after {
      @extend .icon-before, .fa;
      color: $text-color;
      font-size: 14px;
      content: "\f002";
      left: 10px;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &.msg-box {
    &:before {
      @include prefix(transform, none);
      top: 15px;
    }
  }

  &.icon-ip {
    .theme-ip {
      padding-left: 40px;
    }
  }

  .css-b62m3t-container {
    // z-index: 9;
  }

  .css-13cymwt-control {
    &:hover {
      border: 1px solid $danger-color;
      font-size: 12px;
    }
  }
}

/*********override css end*********/

/*********custom animation ends*********/

.slick-arrow {
  @extend .y-abs, .inline-flex, .items-center, .justify-center, .radius-50;
  font-size: 0;
  padding: 0;
  border: none;
  background: none;
  height: 35px;
  width: 35px;
  cursor: pointer;

  &:hover:after {
    border-color: $dark-2 !important;
  }

  &.slick-prev {
    left: 0;

    &:after {
      left: 0%;
      border-left: 2px solid $theme-color;
      border-bottom: 2px solid $theme-color;
    }
  }

  &.slick-next {
    right: 0;

    &::after {
      right: 0%;
      border-right: 2px solid $theme-color;
      border-top: 2px solid $theme-color;
    }
  }

  &:after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    @include prefix(transform, translateY(-50%) rotate(45deg));
    @extend .slow;
  }
}

.custom-upload {
  position: relative;

  .box {
    border: 2px dashed $theme-color;
    background: rgba($theme-color, 0.03);
    @extend .radius-8;
    padding: 20px 15px;
    text-align: center;
    font-size: 13px;
    color: $text-light;
  }

  i {
    display: block;
    font-size: 34px;
    margin-bottom: 5px;
    color: $theme-color;
  }

  .ip {
    @extend .full-abs;
    z-index: 3;
    cursor: pointer;
    opacity: 0;
  }

  .theme-ip {
    @extend .flex, .items-center;
    padding-right: 5px;
    padding-left: 5px;

    .theme-btn {
      margin-left: auto;
    }

    i {
      font-size: 18px;
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
}

.upfile {
  @extend .radius-5, .flex, .items-center;
  padding-bottom: 3px;
  position: relative;
  margin-top: 6px;
  padding-top: 1px;
  margin-bottom: 10px;
  padding-left: 11px;
  padding-right: 5px;

  .text {
    margin-right: auto;
    margin-left: 6px;
    font-size: 13px;

    line-height: 1.1;
    white-space: nowrap;
    //: ;
    // width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;

    .type {
      font-size: 11px;
      @extend .f-med;
      opacity: 0.5;
    }

    .name {
      white-space: nowrap;
      // width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;

      .attachment-name {
        white-space: nowrap;
        // width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        padding-right: 10px;
      }
    }
  }

  i {
    color: $theme-color;
  }

  .close {
    cursor: pointer;
    color: $dark-2;
  }

  .progress {
    background: rgba($theme-color, 0.07);
    position: absolute;
    bottom: 0;
    height: 7px;
    @extend .radius-5;
    overflow: hidden;
    left: 0;
    width: 100%;

    .inner {
      background: $theme-color;
      height: 100%;
    }
  }

  &.in-process {
    padding-bottom: 14px;
  }
}

/******Aditya*******/
.uploaded-files {
  overflow-y: auto;
  max-height: 50vh;

  .form-label {
    position: sticky;
    display: block;
    background: $white;
    top: 0;
    z-index: 9;
  }

  // .text {
  //   white-space: nowrap;
  //   width: 200px;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   display: block;

  //   // overflow: hidden;
  //   // text-overflow: ellipsis;
  //   // display: -webkit-box;
  //   // // line-height: 16px;     /* fallback */
  //   // // max-height: 32px;      /* fallback */
  //   // // -webkit-line-clamp: 1; /* number of lines to show */
  //   // // -webkit-box-orient: vertical;
  //   // max-width: 200px;
  // }
}

/*******Aditya*******/

/*********notices css starts*********/
.alter-link {
  color: $dark;
  @extend .f-normal;

  &:hover {
    color: lighten($dark, 10);
  }
}

/*********notices css ends*********/

/*********sidebar start*********/
.main-wrapper {
  background: $ip-bg;
  min-height: 100vh;
}

.side-head img {
  max-width: 140px;
}

.side-head .img {
  overflow: hidden;
  @extend .slow;
  @extend .flex, .items-center;

  .fav {
    margin-right: 12px;
  }
}

.side-head {
  @extend .flex, .items-center, .justify-between;
  padding: 18px;
}

.sidebar {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 280px;
  @extend .slow, .flex, .flex-col;
  z-index: 9;
  background: $white;

  .menu {
    margin-top: 25px;
  }

  .menu-links {
    margin-bottom: 0;
    padding: 0 25px;

    .link {
      @extend .flex, .items-center;
      padding: 15px 25px;
      @extend .f-med, .radius-10, .slow;
      color: $text-light;

      i {
        color: $placeholder-color;
        @extend .slow;
      }

      .text {
        margin-left: 15px;
        user-select: none;
      }

      &:hover {
        color: $theme-secondary;

        i {
          color: $theme-secondary;
        }
      }
    }

    .active .link {
      background: rgba($dark, 0.1);
      color: rgba($dark, 0.6);

      i {
        color: inherit;
      }
    }
  }

  .menu-bar {
    @extend .flex, .flex-col;
    flex-grow: 1;
    overflow: auto;
    overflow-x: hidden;
  }

  .bottom-content {
    margin-top: auto;
    border-top: 1px solid $ip-bg;
  }
}

.sidebar-open {
  .sidebar {
    width: 70px;

    .menu-links {
      padding: 0 5px;

      .link {
        padding-left: 23px;

        .text {
          white-space: nowrap;
          opacity: 0;
        }
      }
    }

    .side-head .img {
      width: 0;
    }
  }

  .admin-home {
    width: calc(100vw - 70px);
    margin-left: 70px;
  }

  .sidebar-toggler {
    .bar {
      &.m {
        margin-left: 0;

        &:after {
          @include prefix(transform, rotate(225deg));
          left: 25px;
        }
      }
    }
  }
}

.admin-home {
  width: calc(100vw - 280px);
  @extend .slow;
  margin-left: 280px;
}

.sidebar-toggler {
  display: inline-block;
  vertical-align: middle;

  .bar {
    height: 2px;
    width: 25px;
    background: $light;
    @extend .radius-3, .slow;
    position: relative;
    display: block;
    margin-top: 7px;

    &:first-child {
      margin: 0;
    }

    &.m {
      width: 15px;
      margin-left: auto;

      &:after {
        content: "";
        position: absolute;
        height: 8px;
        width: 8px;
        border-left: 2px solid $light;
        border-bottom: 2px solid $light;
        bottom: 1px;
        left: -10px;
        @include prefix(transform, rotate(45deg));
        transform-origin: bottom left;
      }
    }
  }
}

.admin-header {
  background: $white;
  border-left: 1px solid $ip-bg;
  // width: calc(100% - 339PX);
  right: 30px;
  z-index: 999;
  position: sticky;
  top: 0;
}

// .accordion-item {
//   overflow: hidden;
//   transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
//   height: auto;
//   max-height: 9999px;
// }

// .accordion-item.collapsed {
//   max-height: 0;
//   transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
// }

/*********sidebar ends*********/

/*********filter sidebar starts*********/
.filter-sidebar {
  padding: 20px;

  .custom-checkbox {
    margin-bottom: 5px;
  }

  .child {
    padding-left: 20px;
    flex-wrap: wrap;

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      opacity: 0.5;
    }

    .flex .custom-checkbox {
      margin-right: 12px;
    }

    .child {
      margin-top: 10px;
    }
  }

  .filter-block {
    margin-bottom: 20px;
  }

  .text {
    color: $text-light;
  }

  .select-outer {
    .theme-ip {
      padding-right: 15px;
    }

    &:after {
      right: 6px;
    }
  }

  &.list-tab {
    padding-top: 0;
  }
}

.form-group {
  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
}

.profile-picture {
  width: 200px;
  // height: 260px;
  border-radius: 50%;
  overflow: hidden;
  @extend .full-flex;
  padding: 4px;
  background-color: rgba($danger-color, 0.3);
  margin-bottom: 25px;
  cursor: pointer;

  &:hover {
    opacity: 0.3;
  }

  &:hover .edit-img {
    left: 50%;
    opacity: 9;
    top: 33.4%;
    color: black;
    font-size: 35px;
  }

  .edit-img {
    transition: 0.2s ease;
    opacity: 0;
    position: absolute;
    top: 31%;
    left: 50.1%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .profile-img {
    //  height: 100%;
    border-radius: 50%;
  }
}

.filter-card {
  .left {
    flex-basis: 330px;
    max-width: 330px;
    @extend .pl-3;

    form {
      position: sticky;
      display: block;
      background: #fff;
      top: 60px;
      bottom: 0;
      z-index: 99;
      min-height: calc(100vh - 166px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .filter-sidebar {
      overflow-y: auto;
      // background: red;
      height: 402px;
      flex-grow: 1;
      // min-height: calc(100vh - 353px);
      // max-height: 300px;
    }
  }

  .right {
    @extend .pr-3;
    flex-basis: calc(100% - 330px);
    max-width: calc(100% - 330px);

    .table-outer {
      border-left: 1px solid $ip-bg;
      min-height: 100%;

      .table {
        thead {
          tr {
            position: sticky;
            /* display: block; */
            background: #fff;
            top: 0;
            z-index: 9;
          }
        }
      }
    }

    .theme-table {
      max-height: calc(100vh - 200px);
      overflow-y: auto;

      & > .table {
        & > thead > tr > th,
        & > tbody > tr > td {
          text-align: left;

          height: 49px;
        }
      }
    }
  }

  .table-responsive {
    overflow-x: auto;

    .table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
    }
  }

  &.failed {
    background-color: rgba($danger-color, 0.1);
    padding: 5px 1px 5px 10px;

    .text {
      .name {
        padding: 6px 6px 6px 0px;

        .attachment-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }

      .error {
        font-size: 12px;
      }
    }
  }

  .text {
    margin-right: 10px;

    .name {
      .attachment-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

/*********filter sidebar Ends*********/

/*********header start*********/
.z-5 {
  z-index: 5;
  position: relative;
}

.z-6 {
  z-index: 6;
}

.select-drop {
  .theme-ip {
    z-index: 98;
    user-select: none;
  }

  .icon-before {
    z-index: 99;
  }

  .search-autodrop {
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 55px;
  }

  .theme-ip {
    height: 38px;
    @extend .flex, .items-center;
    cursor: pointer;
  }

  .sec {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 15px;
    margin-bottom: 17px;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
      border: none;
    }
  }

  .md-head {
    margin-bottom: 0px;
  }

  .theme-btn {
    color: $white;
  }
}

.check-btn {
  position: relative;

  .radio {
    @extend .full-abs;
    z-index: 2;
    opacity: 0;
    cursor: pointer;

    &:not(:checked) + .theme-btn {
      background-color: #ddd;
    }
  }

  .theme-btn {
    color: $white;
  }
}

.check-item {
  @extend .flex, .justify-center, .items-center;
  position: relative;
  @extend .f-medium;

  .check {
    @extend .full-abs;
    z-index: 2;
    cursor: pointer;
    opacity: 0;

    &:checked + i {
      color: $theme-color;
      opacity: 1;
    }

    &:checked ~ .text {
      color: $theme-color;
    }
  }

  i {
    margin-right: 5px;
    opacity: 0;
  }
}

.admin-nav {
  @extend .flex, .items-center, .justify-end;
  padding: 15px 0;

  .search-box {
    margin-right: 15px;
    position: relative;
    z-index: 99;
    min-width: 300px;
  }

  .user-drop {
    .dropdown-toggle {
      height: 40px;
      width: 40px;
      @extend .radius-50, .inline-flex, .justify-center, .items-center, .f-med;
      background: $ip-bg;
      color: $text-color;
      flex-shrink: 0;
      line-height: 1;
      padding-top: 2px;

      &:after {
        display: none;
      }
    }

    .dropdown-menu {
      margin-top: 0;
    }

    &:hover .dropdown-menu {
      display: block;
    }
  }

  .sidebar-toggler {
    .bar {
      background: $text-alter;

      &.m {
        margin-left: 0;

        &:after {
          @include prefix(transform, rotate(225deg));
          left: 25px;
          border-left: 2px solid $text-alter;
          border-bottom: 2px solid $text-alter;
        }
      }
    }
  }

  .search-outer {
    z-index: 98;
  }

  // .search-box {display: none !important;}
}

.theme-ip {
  min-height: 42px;
  height: 42px;

  &[disabled],
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;

    &.up {
      opacity: 1;
    }

    &:hover {
      outline: none;
      border: 1px solid rgba($dark, 0.1);
    }
  }

  @extend .radius-5, .w-full, .slow;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  display: block;
  border: 1px solid rgba($dark, 0.1);
  text-overflow: ellipsis;
  background: $white;

  &:hover,
  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $theme-color;
  }

  &.sm {
    min-height: 30px;
    max-height: 30px;
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 12px;
    @extend .f-med;
    padding-left: 7px;
  }

  &.curve {
    @extend .radius-8;
  }
}

.ip-wrap {
  display: block;

  &.required {
    .form-label {
      &:after {
        content: "*";
        color: $danger-color;
      }
    }
  }
}

.center-logo {
  margin: auto;
}

.dark-sec {
  background-color: $dark-3;

  .theme-ip {
    background: transparent;
    color: $border-clr;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px $dark-3 inset !important;
      color: $border-clr;
      -webkit-text-fill-color: $border-clr;
    }
  }

  .theme-btn {
    background-color: $text-color;
    color: $border-clr;
  }
}

.txt-l-w {
  color: $border-clr;
}

.f-13 {
  font-size: 13px;
}

/*********header ends*********/

/*********login page css starts*********/
.auth-sec {
  @extend .full-flex;
  min-height: 100vh;
  padding: 40px 0;
  background: rgba($dark, 0.05);

  #okta-sign-in.auth-container.main-container {
    font-family: inherit;
    border: none;
    @include prefix(box-shadow, 0 5px 15px rgba(0, 0, 0, 0.03));
    @extend .radius-6;
  }

  #okta-sign-in.auth-container {
    min-width: auto;

    .auth-content {
      padding-bottom: 30px;
      padding-top: 0px;
    }

    .o-form-head {
      color: $dark-2;
      font-size: 18px;
      margin-top: 0;
    }

    .okta-sign-in-header {
      border: none;
    }

    .o-form-fieldset.margin-btm-5 {
      margin-bottom: 15px;
    }

    .o-form-label {
      @extend .form-label;
      padding-bottom: 5px;
    }

    input {
      &:focus,
      &:hover {
        box-shadow: none !important;
        border-color: $theme-color;
      }
    }

    .o-form-explain.o-form-input-error {
      font-size: 13px;
    }

    .okta-form-input-field.focused-input {
      box-shadow: none !important;
      border-color: $theme-color;
    }

    .focused-input {
      box-shadow: none !important;
    }

    .okta-form-input-field {
      border-color: rgba($dark, 0.1);
      @extend .radius-5;

      &:focus,
      &:hover {
        box-shadow: none !important;
        border-color: $theme-color;
      }
    }

    .beacon-loading:after {
      border-top-color: $theme-color;
    }

    .button-primary {
      @extend .theme-btn;
      line-height: normal;
      background: $theme-color;
      @extend .slow;
      border: none !important;
      box-shadow: none !important;

      &:hover,
      &:focus {
        background: $theme-color;
      }

      &.link-button-disabled.button {
        background: $theme-color;
        opacity: 0.9;
      }
    }

    .auth-org-logo {
      max-height: 60px;
    }

    .auth-footer {
      display: none;
    }

    a:hover {
      text-decoration: none;
      color: $theme-color;
    }

    a:focus {
      box-shadow: none;
      text-decoration: none;
    }

    .auth-header {
      padding: 30px 55px 75px;
    }
  }

  .auth-container {
    min-width: auto;
  }
}

.auth-form {
  .top {
    margin-bottom: 20px;
  }
}

/*********login page css ends*********/

/*********Dashboard starts*********/
.chart {
  height: 100%;

  .chart-container {
    // max-width: 700px;
    // max-height: 400px;
    height: 100%;

    canvas {
      width: 100% !important;
      height: 406px !important;

      // @media screen and (max-width:500px) {
      //   height: 406px !important;

      // }
    }
  }
}

.theme-card {
  @extend .radius-8;
  background: $white;
  margin-bottom: 25px;

  .body,
  .card-head {
    padding: 20px 25px 10px;
    width: 100%;
  }

  &.sm {
    .body {
      padding: 20px 15px;
    }
  }

  &.admin-h {
    @extend .full-flex;
    min-height: calc(100vh - 160px);
    &.automation {
      align-items: unset;
    }
  }
}

.btn-list {
  .theme-btn {
    margin-bottom: 65px;
  }
}

.admin-tabs {
  &.mx-card {
    margin-left: -25px;
    margin-right: -25px;
  }

  .inner {
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(130px, 2fr));
    @extend .flex, .radius-5;
    list-style: none;
    gap: 2px;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid $ip-bg;
    padding: 10px 15px 0px;
    flex-wrap: wrap;
  }

  .react-tabs__tab {
    padding: 0;
    border: none;
    flex: 0 0 auto;

    &.react-tabs__tab--selected {
      .tab-link {
        color: $white;
        background: $theme-secondary;
      }
    }
  }

  .icon-before {
    pointer-events: none;
  }

  .theme-ip,
  .tab-link {
    border: none !important;
    font-size: 12px !important;
    // @extend .f-med;
  }

  .tab-link {
    color: inherit;
    @extend .flex, .items-center, .justify-center;
    height: 24px;
    padding: 0 8px;
    color: $white;
    position: relative;
    background: $theme-color;
    @include prefix(border-radius, 3px 3px 0 0);
    cursor: pointer;

    i {
      margin-right: 10px;
    }

    // &:after {content: '';position: absolute;bottom: 0;height: 3px;left: 0;width: 100%;z-index: 1;}
    &.active {
      background: $text-color;
      color: $white;

      &:after {
        background: $theme-color;
      }
    }

    &:hover {
      background: darken($theme-color, 20);
      color: $white;
    }

    &:hover:after {
      background-color: $ip-bg;
    }

    &.done {
      position: relative;
      @extend .inline-flex, .items-center;

      &:before {
        @extend .fa;
        content: "\f058";
        color: $white;
        margin-right: 5px;
      }

      &.active {
        &:before {
          color: $white;
        }
      }
    }
  }
}

.center-logo {
  margin: auto;
}

.dark-sec {
  background-color: $dark-3;

  .theme-ip {
    background: transparent;
    color: $border-clr;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px $dark-3 inset !important;
      color: $border-clr;
      -webkit-text-fill-color: $border-clr;
    }
  }

  .theme-btn {
    background-color: $text-color;
    color: $border-clr;
  }
}

.txt-l-w {
  color: $border-clr;
}

.f-13 {
  font-size: 13px;
}

/*********header ends*********/

/*********login page css starts*********/
.auth-sec {
  @extend .full-flex;
  min-height: 100vh;
  padding: 40px 0;
  background: rgba($dark, 0.05);

  #okta-sign-in.auth-container.main-container {
    font-family: inherit;
    border: none;
    @include prefix(box-shadow, 0 5px 15px rgba(0, 0, 0, 0.03));
    @extend .radius-6;
  }

  #okta-sign-in.auth-container {
    min-width: auto;

    .auth-content {
      padding-bottom: 30px;
      padding-top: 0px;
    }

    .o-form-head {
      color: $dark-2;
      font-size: 18px;
      margin-top: 0;
    }

    .okta-sign-in-header {
      border: none;
    }

    .o-form-fieldset.margin-btm-5 {
      margin-bottom: 15px;
    }

    .o-form-label {
      @extend .form-label;
      padding-bottom: 5px;
    }

    input {
      &:focus,
      &:hover {
        box-shadow: none !important;
        border-color: $theme-color;
      }
    }

    .o-form-explain.o-form-input-error {
      font-size: 13px;
    }

    .okta-form-input-field.focused-input {
      box-shadow: none !important;
      border-color: $theme-color;
    }

    .focused-input {
      box-shadow: none !important;
    }

    .okta-form-input-field {
      border-color: rgba($dark, 0.1);
      @extend .radius-5;

      &:focus,
      &:hover {
        box-shadow: none !important;
        border-color: $theme-color;
      }
    }

    .beacon-loading:after {
      border-top-color: $theme-color;
    }

    .button-primary {
      @extend .theme-btn;
      line-height: normal;
      background: $theme-color;
      @extend .slow;
      border: none !important;
      box-shadow: none !important;

      &:hover,
      &:focus {
        background: $theme-color;
      }

      &.link-button-disabled.button {
        background: $theme-color;
        opacity: 0.9;
      }
    }

    .auth-org-logo {
      max-height: 60px;
    }

    .auth-footer {
      display: none;
    }

    a:hover {
      text-decoration: none;
      color: $theme-color;
    }

    a:focus {
      box-shadow: none;
      text-decoration: none;
    }

    .auth-header {
      padding: 30px 55px 75px;
    }
  }

  .auth-container {
    min-width: auto;
  }
}

.auth-form {
  .top {
    margin-bottom: 20px;
  }
}

/*********login page css ends*********/

/*********Dashboard starts*********/
.chart {
  height: 100%;

  .chart-container {
    // max-width: 700px;
    // max-height: 400px;
    height: 100%;

    canvas {
      width: 100% !important;
      height: 406px !important;

      // @media screen and (max-width:500px) {
      //   height: 406px !important;

      // }
    }
  }
}

.theme-card {
  @extend .radius-8;
  background: $white;
  margin-bottom: 25px;

  .body,
  .card-head {
    padding: 20px 25px 20px;
    width: 100%;
  }

  &.sm {
    .body {
      padding: 20px 15px;
    }
  }

  &.admin-h {
    @extend .full-flex;
    min-height: calc(100vh - 160px);
  }

  // Aditya
  &.not-found-page {
    min-height: calc(100vh - 136px);
    display: flex;
    justify-content: center;
    align-items: center;

    .body {
      text-align: center;
    }
  }

  &.profile-content {
    min-height: calc(100vh - 200px);
    @extend .full-flex;
    padding-bottom: 15px;
  }
}

.btn-list {
  .theme-btn {
    margin-bottom: 65px;
  }
}

.admin-tabs {
  &.mx-card {
    margin-left: -25px;
    margin-right: -25px;
  }

  .inner {
    @extend .flex, .radius-5;
    list-style: none;
    gap: 2px;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid $ip-bg;
    padding: 10px 15px 0px;
    flex-wrap: wrap;
  }

  .react-tabs__tab {
    padding: 0;
    border: none;

    &.react-tabs__tab--selected {
      .tab-link {
        color: $white;
        background: $theme-secondary;
      }
    }
  }

  .icon-before {
    pointer-events: none;
  }

  .theme-ip,
  .tab-link {
    border: none !important;
    font-size: 13px;
    @extend .f-med;
  }

  .tab-link {
    color: inherit;
    @extend .flex, .items-center, .justify-center;
    height: 24px;
    padding: 0 8px;
    color: $white;
    position: relative;
    background: $theme-color;
    @include prefix(border-radius, 3px 3px 0 0);
    cursor: pointer;

    i {
      margin-right: 10px;
    }

    // &:after {content: '';position: absolute;bottom: 0;height: 3px;left: 0;width: 100%;z-index: 1;}
    &.active {
      background: $text-color;
      color: $white;

      &:after {
        background: $theme-color;
      }
    }

    &:hover {
      background: darken($theme-color, 20);
      color: $white;
    }

    &:hover:after {
      background-color: $ip-bg;
    }

    &.done {
      position: relative;
      @extend .inline-flex, .items-center;

      &:before {
        @extend .fa;
        content: "\f058";
        color: $white;
        margin-right: 5px;
      }

      &.active {
        &:before {
          color: $white;
        }
      }
    }
  }
}

.lh-normal {
  line-height: 1.2;
}

.theme-table {
  font-size: 14px;
  @extend .f-med;

  .table {
    margin-bottom: 0;
    width: 100%;
  }

  .expand-cell {
    padding-left: 10px;
    padding-right: 0;
  }

  // thead th {
  //   text-align: left;
  // }

  .child-data {
    .sm-table {
      width: 100%;

      td:first-child {
        padding-left: 10px;
      }

      tr:last-child {
        // td {border-bottom: none;}
      }
    }

    td {
      padding: 8px 5px;
    }

    & > td {
      padding: 0;
      border: none;
      background: rgba($dark, 0.02);
      color: $text-light-2;
    }
  }

  td,
  th {
    padding: 10px 10px;
    vertical-align: middle;
    border-bottom: 1px solid $ip-bg;
  }

  .table-striped {
    td {
      border: none;
      padding: 6px 15px;
    }

    tbody tr:nth-of-type(odd) {
      background-color: rgba($dark, 0.035);
    }
  }

  .tb-actions {
    display: flex;

    .icon-btn {
      margin-right: 5px;
    }
  }
}

.pagination {
  @extend .flex, .justify-end;
  margin-top: 10px;
  padding-right: 20px;

  li {
    margin-right: 3px;
  }

  .link {
    @extend .icon-btn, .xs, .plain, .radius-22, .f-med;
    color: $text-color;

    &:hover {
      background: rgba($dark, 0.07);
      color: $text-light-2;
    }

    &.active {
      background: $theme-color;
      color: $white;
    }
  }
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.sec-info {
  .right {
    .icon-btn {
      margin-top: 10px;
    }
  }
}

.theme-drop {
  @include prefix(box-shadow, 0 5px 20px rgba(0, 0, 0, 0.15));
  border: none;
  @extend .radius-5, .slow;
  position: absolute;
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  background: $white;

  .link {
    display: block;
    padding: 5px 15px;
    color: $text-color;
    @extend .slow;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background: #f7f7f7;
    }

    &.active {
      color: $white;
      background: $theme-color;
    }
  }

  &.dropdown-menu-right {
    left: auto;
    right: 0;
  }

  .link {
    white-space: nowrap;
  }
}

.dropdown {
  position: relative;

  &:hover {
    .dropdown-menu {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.f-14 .f-12 {
  font-size: 12px;
}

.f-13 {
  font-size: 13px;
}

.skel-anime {
  background: #eee;
  color: transparent;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}

.skeleton {
  .sq-icon {
    @extend .skel-anime;
  }

  .price-row {
    @extend .skel-anime, .radius-5;
    color: transparent !important;
    min-height: 30px;
  }

  .big-txt {
    @extend .skel-anime, .radius-5;
    color: transparent !important;
    min-height: 30px;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.ui.modal > .header {
  padding: 0 !important;
}

.pagination {
  justify-content: end;
  margin-bottom: 30px;

  .page-item.disabled .page-link {
    border-color: transparent;
  }

  .page-link {
    height: 30px;
    width: 30px;
    @extend .flex, .items-center, .justify-center;
    border-radius: 20px !important;
    color: initial;
    font-size: 13px;
    font-weight: 600;
    border-color: transparent;
  }

  .page-item {
    margin: 0 3px;

    &.active .page-link {
      background-color: $theme-color;
      border-color: $theme-color;
    }
  }

  li:first-child,
  li:last-child {
    .page-link {
      font-size: 0;

      &:before {
        @extend .fa;
        font-size: 14px;
      }
    }
  }

  li:first-child {
    .page-link {
      &::before {
        content: "\f104";
      }
    }
  }

  li:last-child {
    .page-link {
      &::before {
        content: "\f105";
      }
    }
  }
}

.avatar {
  font-size: 20px;
  height: 80px;
  width: 80px;
  flex-shrink: 0;
  @extend .radius-50, .flex, .items-center, .justify-center, .f-heavy;
  background-color: $theme-color;

  img {
    height: 100%;
    width: 100%;
    @extend .radius-50;
  }

  &.xs {
    height: 32px;
    width: 32px;
    font-size: 12px;
    padding-top: 2px;
  }

  &.grey {
    background: $ip-bg;
  }
}

.f-14 {
  font-size: 14px;
}

.control-head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  align-items: center;

  .head {
    margin-right: auto;
  }

  .back {
    margin-right: 10px;
  }

  .right {
    // display: flex;
    // justify-content: space-between;
    .icon-btn {
      margin-left: 10px;
    }

    text-align: end;
  }
}

/*********Dashboard ends*********/

/*********404 starts*********/
.sec-not-found {
  @extend .flex, .flex-col, .justify-center, .py-4;
  min-height: calc(100vh - 80px);
}

/*********404 ends*********/

/*********modal css ends*********/
.close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 999;

  &:before {
    opacity: 0.5;
  }

  &:hover:before {
    opacity: 1;
  }
}

.cross-icon {
  display: inline-block;
  height: 30px;
  width: 30px;
  border: 2px solid $white;
  position: relative;
  @extend .radius-50;
  @include prefix(transform, rotate(45deg));

  &:after,
  &:before {
    content: "";
    background-color: $white;
    @extend .radius-3, .xy-abs;
  }

  &:after {
    height: 2px;
    width: 50%;
  }

  &:before {
    height: 50%;
    width: 2px;
  }
}

.modal-backdrop.show {
  opacity: 0.8;
}

// .ui.large.modal{
//   min-height: 552px;
//   display: flex !important;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
// }

.common-modal {
  .modal-content {
    @extend .radius-15;
    margin: 40px 0 0;

    .close {
      @extend .cross-icon;
      float: none;
      position: absolute;
      right: 0;
      top: -40px;
      opacity: 1;
    }
  }
}

.audit-modal {
  .description {
  }

  .c-json-view {
    max-height: 450px;
    overflow-y: auto;
  }
}

.Table-outer-audit {
  thead {
    th {
      text-align: left;
    }
  }

  tbody {
    tr {
      text-align: left;
    }
  }
}

.ui {
  .scrolling.content {
    max-height: 100% !important;
  }
}

.scrolling.content {
  min-height: calc(80vh - 10em);

  .react-tabs__tab-panel--selected {
    max-height: calc(80vh - 18em);
    overflow: auto;
    margin-top: -12px;
  }
}

.form-group {
  margin-bottom: 20px;
}

.form-group-sm {
  margin-bottom: 18px;
}

.form-label {
  @extend .f-med;
  font-size: 14px;
  opacity: 0.8;
  color: $text-color;
}

.divider {
  border-top: 2px dashed $ip-bg;
  margin-bottom: 30px;
  margin-top: 20px;
}

// @media screen and (max-width:1024px) {
//   .d-md-none{
//     display: none;
//   }

// }
.divider-mid {
  height: 100%;
}

.form-group {
  margin-bottom: 20px;
}

.form-group-sm {
  margin-bottom: 18px;
}

.form-label {
  @extend .f-med;
  font-size: 14px;
  opacity: 0.8;
}

// .divider {
//   border-top: 2px dashed $ip-bg;
//   margin-bottom: 30px;
//   margin-top: 20px;
// }

.margin-top-25 {
  margin-top: 25px;
}

// .divider-left {border-left: 2px dashed $ip-bg;margin-bottom: 30px;margin-top: 20px;}

/**********Aditya***********/
.divider-left {
  border-left: 2px dashed rgba($dark, 0.4);
}

/**********Aditya***********/
.sticky-tab-list {
  position: sticky;
  display: block;
  background: $white;
  top: 0;
  z-index: 99;
}

.theme-loader {
  position: relative;
  height: 50vh;
  min-height: 200px;
  background: $white;
  display: none;

  &.show {
    display: block;
  }

  &.auto {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }

  &.fixed {
    background: rgba($dark, 0.7);
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 9999;
  }

  .loader-outer {
    @extend .xy-abs;
    background-color: $white;
    padding: 30px;
    @extend .radius-10;
  }
}

.main-data {
  position: relative;
  padding: 20px 0;

  .theme-loader.auto {
    left: 0;
  }
}

.loader,
.loader:after {
  @extend .radius-50;
  width: 6em;
  height: 6em;
}

.loader {
  margin: 0;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.1);
  border-right: 1.1em solid rgba(0, 0, 0, 0.1);
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.1);
  border-left: 1.1em solid $theme-color;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  border-width: 7px;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ReactModal__Overlay {
  background: rgba($dark, 0.7) !important;
  z-index: 15;

  .ReactModal__Content {
    border: none !important;
    border-radius: 10px !important;
  }

  .cross-icon {
    background-color: rgba($dark, 0.1);
    height: 24px;
    width: 24px;
  }
}

.Toastify {
  [fill="var(--toastify-icon-color-success)"] {
    fill: $active-color;
  }

  .Toastify__progress-bar--success {
    background-color: $active-color;
  }
}

.quill {
  @extend .flex, .flex-col-reverse;
  margin-bottom: 20px;

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: none;
  }

  .ql-editor.ql-blank::before {
    font-style: normal;
    @extend .f-normal;
    color: $text-light-2;
    opacity: 1;
    font-size: 14px;
    left: 0;
  }

  .ql-editor {
    padding-left: 0;
    min-height: 180px;
    padding-top: 0;
    padding-right: 0;
  }

  .ql-toolbar.ql-snow {
    border: none;
    padding-left: 0;
    border-bottom: 1px solid #ccc;
    padding-bottom: 16px;
  }

  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: $dark;
  }

  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: $dark;
  }

  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    border: 1px solid rgba($dark, 0.13);
    @extend .radius-5;
    @include prefix(box-shadow, 0 1px 3px rgba($dark, 0.05));
    margin-right: 8px;
    margin-bottom: 7px;

    &.ql-active {
      background-color: rgba($dark, 0.04);

      svg {
        opacity: 0.8;
      }
    }
  }

  .ql-toolbar.ql-snow .ql-formats:not(:first-child) {
    margin-right: 0;
  }

  .ql-snow .ql-picker-label {
    padding-left: 0;
  }

  .ql-snow .ql-formats {
    vertical-align: top;
  }
}

/*********modal css ends*********/

/*********list css starts*********/
.bullet-list {
  padding-left: 20px;
  list-style: disc;

  li {
    margin-bottom: 5px;
  }
}

.light-link {
  color: $placeholder-color;

  i {
    font-size: 1.2em;
  }
}

/*********list css ends*********/

/*********custom checkbox css start*********/
.shadow-none {
  box-shadow: none !important;
}

.custom-control-label {
  &:before {
    background-color: transparent;
    border: 1px solid $light;
    box-shadow: none !important;
  }

  &:before,
  &:after {
    @include prefix(box-shadow, none);
    outline: none;
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: $theme-color;
  border: none;
}

.cursor {
  cursor: pointer;
}

.custom-checkbox {
  position: relative;
  z-index: 1;
  @extend .flex;
  color: $text-light;

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }

  .ip {
    @extend .full-abs;
    z-index: 1;
    opacity: 0;

    &:checked + .check {
      background: $theme-color;
      border-color: transparent;

      &:after {
        opacity: 1;
      }
    }
  }

  .check {
    border: 1px solid $text-light;
    @extend .slow, .radius-3;
    height: 16px;
    width: 17px;
    margin-right: 5px;
    margin-top: 2px;
    flex-shrink: 0;

    &:after {
      content: "";
      position: absolute;
      border-left: 2px solid $white;
      border-bottom: 2px solid $white;
      @include prefix(transform, rotate(-45deg));
      height: 5px;
      width: 9px;
      top: 6px;
      left: 4px;
      opacity: 0;
    }
  }
}

/*********custom checkbox css ends*********/

/*********checkout css starts*********/
.dark {
  color: $dark-2;
}

/*********checkout css ends*********/

/*********manager app css starts*********/
.theme-tabs {
  background: $text-color;
  margin: 0 -30px 15px;
  display: flex;
  padding: 0 30px;
  text-transform: uppercase;
  font-size: 12px;

  .item {
    display: flex;
  }

  .link {
    color: $white;
    padding: 12px 15px;
    display: inline-block;
    border-left: 1px solid rgba($white, 0.2);
    border-right: 1px solid rgba($white, 0.2);
    margin-left: -1px;
    cursor: pointer;
  }

  .active,
  .react-tabs__tab--selected {
    .link {
      background: $dark-3;
      border-color: transparent;
    }
  }

  .listing {
    display: flex;
    margin-bottom: 0;
  }

  .view-toggle {
    margin-left: auto;
    color: $white;
    display: flex;

    .icon {
      display: flex;
      align-items: center;
      margin-left: 15px;
      color: $white;
      font-size: 20px;
      opacity: 0.7;

      &.active {
        opacity: 1;
      }
    }
  }
}

.find-tab-scroll {
  &.react-tabs__tab-panel--selected {
    height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.add-btn {
  font-size: 18px;
  color: $theme-color;
}

.app-bar {
  position: fixed;
  right: 0;
  top: 0;
  background: $white;
  height: 100vh;
  z-index: 99;
  width: 450px;
  @include prefix(box-shadow, -10px 0 15px rgba($dark, 0.05));
  padding: 10px 15px;
  @extend .slow;
  @include prefix(transform, translateX(100%));
  opacity: 0;
  display: flex;
  flex-direction: column;

  .inner {
    display: flex;
    flex-direction: column;
  }

  &.open {
    @include prefix(transform, translateX(0));
    opacity: 1;
  }

  .content {
    margin-top: 40px;
    padding: 0 15px;
  }

  .theme-btn.width {
    min-width: 210px;
  }

  .data {
    margin-top: 40px;
  }

  .bar-close {
    cursor: pointer;
  }

  .theme-btn.border-btn {
    &:hover {
      background: $theme-color;
      color: $white;
      border-color: transparent;
    }
  }
}

.common-table-outer {
  @include prefix(border-radius, 0px 0px 8px 8px);
  border-width: 0px 0px 1px 0px;
  margin-bottom: 10px;

  .tabs-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right {
      margin-left: auto;
      padding: 5px 0;
    }

    .react-tabs__tab-list {
      margin-bottom: 0;
      border: none;
    }

    li {
      padding: 8px 12px;
      @extend .f-med;
      font-size: 14px;
    }

    li:after {
      display: none;
    }

    .react-tabs__tab--selected {
      border-color: $border-clr;
      background: #f7f7f7;
    }

    .react-tabs {
      width: 100%;
    }

    .right {
      display: flex;
    }
  }

  .filters,
  .body {
    @extend .b-x;
  }

  .filters {
    border-bottom: none;
    @extend .b-t;
    @include prefix(border-radius, 0px 8px 0px 0px);
    min-height: 50px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .table {
    thead {
      th {
        border-bottom-width: 1px;
      }
    }
  }

  .add-more {
    @extend .theme-btn, .sm;
    color: $white;
  }

  .search {
    width: 30%;
  }

  .sort {
    cursor: pointer;
    user-select: none;

    i {
      margin-left: 5px;
    }
  }

  .action {
    opacity: 0.5;
    color: $dark-2;
    margin-right: 10px;
  }
}

.common-pagin {
  font-size: 14px;
  margin-bottom: 20px;

  .right {
    margin-left: auto;
  }

  .act-btn {
    @extend .border,
      .rounded,
      .inline-flex,
      .items-center,
      .justify-center,
      .slow;
    margin-left: 5px;
    height: 30px;
    width: 32px;
    font-size: 12px;

    &:hover {
      background: $ip-bg;
      border-color: transparent;
    }

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        background: transparent;
      }
    }
  }

  .page-no {
    width: 50px;
  }
}

.custom-switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 22px;
  vertical-align: middle;

  .toggle {
    background: rgba($dark, 0.1);
    @extend .full-abs, .radius-20, .slow;

    &:after {
      content: "";
      @extend .y-abs, .slow, .radius-22;
      height: 18px;
      width: 18px;
      background: $white;
      left: 2px;
      @include prefix(box-shadow, 0 2px 2px rgba($dark, 0.15));
    }
  }

  .ip {
    @extend .full-abs;
    z-index: 2;
    opacity: 0;

    &:checked {
      & + .toggle {
        background: $theme-color;

        &:after {
          left: 26px;
        }
      }
    }
  }
}

.model-3-table {
  height: 600px;
  overflow-y: auto;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 16px;
}

/*********manager app css ends*********/

// React Chatbot styles

.chatbot-trigger {
  position: fixed;
  bottom: 3rem;
  right: 4rem;
  background: $theme-color;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.chatbot-wrapper {
  position: fixed;
  bottom: 8rem;
  right: 3rem;
  z-index: 10;
}

.chatbot-trigger svg {
  transform: scale(1.3);
}

.react-chatbot-kit-chat-container {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.react-chatbot-kit-user-avatar-container {
  background-color: #ededed;
}

.react-chatbot-kit-chat-bot-message {
  background-color: #ededed;
  color: $text-color;
}

.react-chatbot-kit-chat-bot-message-arrow {
  border-right: 8px solid #ededed;
}

.react-chatbot-kit-chat-btn-send {
  background-color: #ededed;
}

.react-chatbot-kit-chat-btn-send-icon {
  fill: $text-color;
}

.react-chatbot-kit-chat-input {
}

.bg-input {
  background: $ip-bg;
}

.text-color {
  color: $text-color;
}

.find-tab-scroll-trail {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.text-primary {
  color: $theme-color;
}

.background-primary {
  background: $theme-color;
}

@import "responsive.scss";
